import BackendAuthService from "./BackendAuthService";

export default class ShortenUrlsService extends BackendAuthService {

	constructor() {
		super();
	}

	/**
	 * Submit ajax get
	 * @param {string} url
	 * @param {object} params
 	*/
	doGet(url, params) {
		let config = {
			method: 'GET',
		}
		if (params) {
			config['params'] = params
		}
		return this.httpCall(url, config);
	}

	/**
	 * Submit ajax post
	 * @param {string} url
	 * @param {FormData|Object} data
 	*/
	doPost(url, data) {
		let config = {
			method: 'POST',
		}
		if (data) {
			config['data'] = data
		}
		return this.httpCall(url, config);
	}

	create(data) {
		let url = 'interfaz/shorten-url-cppl'
		return this.doPost(url, data)
	}

}

