import RecomendadorCatEndpointsService from '../../services/RecomendadorCatEndpointsService'
import ShortenUrlsService from '../../services/ShortenUrlsService'
const interfazCatService = new RecomendadorCatEndpointsService();
const shortenUrlsService = new ShortenUrlsService()

export default {
    namespaced: true,
    state: { 
        // 
        productsList: [],
        productsListLoading: false,
        productsComparadorList: [],
        form: {
            // piezas_mismo_modelo: '',
            tipo_sala: '',
            modelo: '',
            color: '',
            estilo: '',
            material_tapizado: '',
            //extras
            precioMaximo:  0,
            orden: 'Ninguno',
            estado: '',
            ciudad: '',
        },
        // piezasMismoModeloList: [1,2,3],
        tipoSalaList: [],
        modeloList: [],
        colorList: [],
        estiloList: [],
        materialTapizadoList: [],
        //
        precioMaximo: 32000,
        inputsDataLoading: false
    },
    mutations: {
        SET_PRODUCTS_LIST(state, items) {
            state.productsList = items.slice(0,20);
        },
        SET_PRODUCTS_COMPARADOR_LIST(state, items) {
            state.productsComparadorList = items.slice(0,3);
        },
        //
        // SET_PIEZAS_MISMO_MODELO_LIST(state, data){
        //     state.piezasMismoModeloList = data;
        // },
        SET_TOPO_SALA_LIST(state, data){
            state.tipoSalaList = data;
        },
        SET_MODELO_LIST(state, data){
            state.modeloList = data;
        },
        SET_COLOR_LIST(state, data){
            state.colorList = data;
        },
        SET_ESTILO_LIST(state, data){
            state.estiloList = data;
        },
        SET_MATERIAL_TAPIZADO_LIST(state, data){
            state.materialTapizadoList = data;
        },
        //
        CLEAN_FORM(state) {
            // state.form.piezas_mismo_modelo = ''
            state.form.tipo_sala = ''
            state.form.modelo = ''
            state.form.color = ''
            state.form.estilo = ''
            state.form.material_tapizado = ''
            //
            state.form.precioMaximo =  0
            state.form.orden = 'Ninguno'
        },
        RESET_SECTION(state) {
            // state.form.piezas_mismo_modelo = ''
            state.form.tipo_sala = ''
            state.form.modelo = ''
            state.form.color = ''
            state.form.estilo = ''
            state.form.material_tapizado = ''
            state.form.orden = 'Ninguno'
            state.form.precioMaximo =  0
            //
            state.productsList = [];
            state.productsComparadorList = [];

            // state.piezasMismoModeloList = [];
            state.tipoSalaList = [];
            state.modeloList = [];
            state.colorList = [];
            state.estiloList = [];
            state.materialTapizadoList = [];
        },
    },
    actions: {
        fetchSeleccionadorInputs({commit, state}) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            var filters = []
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value != '' && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            state.inputsDataLoading = true
            interfazCatService
                .fetchInputsSeleccionadorSalas(params)
                .then((response)=> {
                    const {tipo_sala, modelo, color, estilo, material_tapizado} = response.data.filtros;
                    // commit('SET_PIEZAS_MISMO_MODELO_LIST', Object.values(piezas_mismo_modelo))
                    commit('SET_TOPO_SALA_LIST', Object.values(tipo_sala))
                    commit('SET_MODELO_LIST', Object.values(modelo))
                    commit('SET_COLOR_LIST', Object.values(color))
                    commit('SET_ESTILO_LIST', Object.values(estilo))
                    commit('SET_MATERIAL_TAPIZADO_LIST', Object.values(material_tapizado))
                })
                .catch((error)=>{
                    console.log("Error fetching inputs salas :(");
                })    
                .finally(() => {
                    state.inputsDataLoading = false;
                })
        },
        submitForm({commit, state}, callback) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            let paramsList = []
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value != '' && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    paramsList.push(`${key}=${value}`)
                }
            });
            params['filters'] = paramsList.length > 0 ? paramsList.join('&') : 'None' 
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['orden'] = state.form.orden
            params['precioMaximo'] = state.form.precioMaximo > 0 ? state.form.precioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormSalas(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }

                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        updateProductsList__isSelectedForComparison({state, commit}) {
            const skusComparadorList = state.productsComparadorList.map(item => item.SKU);
            // productsList
            let itemsList = state.productsList;
            itemsList = itemsList.map(item => {
                let status = skusComparadorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
            commit('SET_PRODUCTS_LIST', itemsList);
        },
        addIProductToCompareList({state, commit, dispatch}, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = state.productsComparadorList;
                let someItem = items.findIndex(i => i.SKU == payload.SKU);
                if(someItem == -1){
                    items.unshift(payload)
                    commit('SET_PRODUCTS_COMPARADOR_LIST', items.slice(0,3))
                }
                dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        removeProductFromCompareList(context, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = context.state.productsComparadorList;
                let filtered = items.filter(i => i.SKU !== payload.SKU);
                if(filtered){
                    context.commit('SET_PRODUCTS_COMPARADOR_LIST', filtered.slice(0,3));
                }
                context.dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        cleanCompareList(context, payload) {
            context.commit('SET_PRODUCTS_COMPARADOR_LIST', []);
        },
        resetSeleccionador(context) {
            context.commit('RESET_SECTION')
        },
        cleanForm(context) {
            context.commit('CLEAN_FORM')
        },
        //
        shortenLink({state, commit}, payload) {
            const items = state.productsList //
            const index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        commit('SET_PRODUCTS_LIST', items) // recomendaciones   
                    }
                })
                .finally(()=> {
                    this.shortenLinkLoading;
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    // getters: {}
}