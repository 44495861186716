
import RecomendadorCatEndpointsService from '../../services/RecomendadorCatEndpointsService'
import ShortenUrlsService from '../../services/ShortenUrlsService'
const interfazCatService = new RecomendadorCatEndpointsService();
const shortenUrlsService = new ShortenUrlsService()

export default {
    namespaced: true,
    state: { 
        productsList: [],
        products2List: [],
        productsListLoading: false,
        productsComparadorList: [],
        shortenLinkLoading: false,
        form: {
            marca: '',
            modelo_auto: '',
            anio: '',
            //extras
            precioMaximo:  0,
            orden: 'Ninguno',
            estado: '',
            ciudad: '',
        },
        marca: [],
        modelo_auto: [],
        anio: [],
        precioMaximo: 8000,
        inputsDataLoading: false

    },
    mutations: {
        SET_PRODUCTS_LIST(state, items) {
            state.productsList = items.slice(0,20);
        },
        SET_PRODUCTS2_LIST(state, items) {
            state.products2List = items.slice(0,20);
        },
        SET_PRODUCTS_COMPARADOR_LIST(state, items) {
            state.productsComparadorList = items.slice(0,3);
        },
        //
        SET_MARCA(state, data){
            state.marca = Object.values(data);
        },
        SET_MODELO_AUTO(state, data){
            state.modelo_auto = Object.values(data);
        },
        SET_ANIO(state, data){
            state.anio = Object.values(data);
        },
        CLEAN_FORM(state) {
            state.form.marca = '';
            state.form.modelo_auto = '';
            state.form.anio = '';
            state.form.precioMaximo =  0
            state.form.orden = 'Ninguno'
        },
        RESET_SECTION(state) {
            state.form.marca = ''
            state.form.modelo_auto = ''
            state.form.anio = ''
            //
            state.marca = [];
            state.modelo_auto = [];
            state.anio = [];
            state.productsList = [];
            state.products2List = [];
            state.productsComparadorList = [];
        },
        SHORTEN_LINK(state, payload) {
            const item = state.productsList.map(i => i.SKU)
            if(!item) {
                return
            }
        }
    },
    actions: {
        fetchSeleccionadorInputs({commit, state}) {
            if(state.form.estado == '' || state.form.ciudad == '') {
                return
            }
            let params = {}
            var filters = []
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value.length > 0 && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            state.inputsDataLoading = true
            interfazCatService
                .fetchSeleccionadorInputsBaterias(params)
                .then((response)=> {
                    const {marca, modelo_auto, anio} = response.data.filtros;
                    commit('SET_MARCA', marca)
                    commit('SET_MODELO_AUTO', modelo_auto)
                    commit('SET_ANIO', anio)
                
                })
                .catch((error)=>{
                    console.log("Error fetching inputs baterias :(");
                })    
                .finally(() => {
                    state.inputsDataLoading = false;
                })
        },
        submitForm({commit, state}, callback) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            let paramsList = []
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value.length && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    paramsList.push(`${key}=${value}`)
                }
            });
            params['filters'] = paramsList.length > 0 ? paramsList.join('&') : 'None' 
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['orden'] = state.form.orden
            params['precioMaximo'] = state.form.precioMaximo > 0 ? state.form.precioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormBaterias(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        // tambien le queda
        submitForm2({commit, state}, callback) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            let paramsList = []
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value.length && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    paramsList.push(`${key}=${value}`)
                }
            });
            params['filters'] = paramsList.length > 0 ? paramsList.join('&') : 'None' 
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['orden'] = state.form.orden
            params['precioMaximo'] = state.form.precioMaximo > 0 ? state.form.precioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS2_LIST', []);
            interfazCatService
                .submitFormBaterias2(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null})).slice(0,20)
                    commit('SET_PRODUCTS2_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda 2 :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        updateProductsList__isSelectedForComparison({state, commit}) {
            const skusComparadorList = state.productsComparadorList.map(item => item.SKU);
            // productsList
            let itemsList = state.productsList;
            itemsList = itemsList.map(item => {
                let status = skusComparadorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
            // products2List
            let items2List = state.products2List;
            items2List = items2List.map(item => {
                let status = skusComparadorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
            commit('SET_PRODUCTS_LIST', itemsList);
            commit('SET_PRODUCTS2_LIST', items2List);
        },
        addIProductToCompareList({state, commit, dispatch}, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = state.productsComparadorList;
                let someItem = items.findIndex(i => i.SKU == payload.SKU);
                if(someItem == -1){
                    items.unshift(payload)
                    commit('SET_PRODUCTS_COMPARADOR_LIST', items.slice(0,3))
                }
                dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        removeProductFromCompareList(context, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = context.state.productsComparadorList;
                let filtered = items.filter(i => i.SKU !== payload.SKU);
                if(filtered){
                    context.commit('SET_PRODUCTS_COMPARADOR_LIST', filtered.slice(0,3));
                }
                context.dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        cleanCompareList(context, payload) {
            context.commit('SET_PRODUCTS_COMPARADOR_LIST', []);
        },
        resetSeleccionador(context) {
            context.commit('RESET_SECTION')
        },
        cleanForm(context) {
            context.commit('CLEAN_FORM')
        },
        //
        shortenLink({state, commit}, payload) {
            const items = state.productsList // recomendaciones
            const index = items.findIndex(i => payload.item.SKU == i.SKU)
            let sendRequest = true
            if (index !== undefined && index > -1 && items[index].shortenedLink !== null) {
                sendRequest = false;
                payload.callback(items[index].shortenedLink);
            }

            const items2 = state.products2List // tambien le queda
            const index2 = items2.findIndex(i => payload.item.SKU == i.SKU)
            if (index2 !== undefined && index2 > -1 && items2[index2].shortenedLink !== null) {
                sendRequest = false;
                payload.callback(items2[index2].shortenedLink);
            }
            if (sendRequest) {
                this.shortenLinkLoading
                shortenUrlsService
                    .create(payload.data)
                    .then((response) => {
                        const data = response.data
                        if (items[index]) {
                            items[index].shortenedLink = data.link
                            commit('SET_PRODUCTS_LIST', items) // recomendaciones   
                        }
                        if (items2[index2]) {
                            items2[index2].shortenedLink = data.link
                            commit('SET_PRODUCTS2_LIST', items2) // tambien le queda   
                        }
                    })
                    .finally(()=> {
                        this.shortenLinkLoading;
                        if (typeof payload.callback === 'function') {
                            const link = items[index] ? items[index].shortenedLink : items2[index2].shortenedLink
                            payload.callback(link);
                        }
                    })
            }
        }
    },
    // getters: {}
}