import store from '../store/index'

const Helpers = {
    install(Vue) {
        Vue.prototype.$checkPermission = function(permissions) {
            let myPermissions = store.getters['session/permissions'];
            //typeof permissions == 'object' || permissions instanceof Object
            if( Array.isArray(permissions) ) {
                return permissions.some( item => myPermissions.includes( item ) )
                //return myPermissions.some( item => permissions.includes( item ) );
            } else {
                return myPermissions.includes(permissions)
            }
        }
    }
}

export default Helpers;