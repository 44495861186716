import RecomendadorCatEndpointsService from '../../services/RecomendadorCatEndpointsService'
import ShortenUrlsService from '../../services/ShortenUrlsService'
// import AcortadorLinkService from '../../services/AcortadorLinksService';
const interfazCatService = new RecomendadorCatEndpointsService();
const shortenUrlsService = new ShortenUrlsService()
// const shortenUrlsService = new AcortadorLinkService()

export default {
    namespaced: true,
    state: { 
        // 
        productsList: [],
        productsListLoading: false,
        form: {
            area: '',
            departamento: '',
            primernivel: '',
            segundonivel: '',
            tercernivel: '',
            cuartonivel: '',
            precioMaximo: 300,
            estado: '',
            ciudad: '',
        },
        area: [],
        departamento: [],
        primernivel: [],
        segundonivel: [],
        tercernivel: [],
        cuartonivel: [],
        inputsDataLoading: false
    },
    mutations: {
        SET_PRODUCTS_LIST(state, payload) {
            state.productsList = payload
        },
        //
        SET_AREA(state, payload){
            let data = []
            if (Array.isArray(payload)) {
                data = payload.filter(item => item != null)
            } 
            state.area = data;
        },
        SET_DEPARTAMENTO(state, payload){
            let data = []
            if (Array.isArray(payload)) {
                data = payload.filter(item => item != null)
            } 
            state.departamento = data;
        },
        SET_PRIMERNIVEL(state, payload){
            let data = []
            if (Array.isArray(payload)) {
                data = payload.filter(item => item != null)
            } 
            state.primernivel = data;
        },
        SET_SEGUNDONIVEL(state, payload){
            let data = []
            if (Array.isArray(payload)) {
                data = payload.filter(item => item != null)
            } 
            state.segundonivel = data;
        },
        SET_TERCERNIVEL(state, payload){
            let data = []
            if (Array.isArray(payload)) {
                data = payload.filter(item => item != null)
            } 
            state.tercernivel = data;
        },
        SET_CUARTONIVEL(state, payload){
            let data = []
            if (Array.isArray(payload)) {
                data = payload.filter(item => item != null)
            } 
            state.cuartonivel = data;
        },
        //
        CLEAN_FORM(state) {
            state.form.area = ''
            state.form.departamento = ''
            state.form.primernivel = ''
            state.form.segundonivel = ''
            state.form.tercernivel = ''
            state.form.cuartonivel = ''
            state.form.precioMaximo = 300
        },
        RESET_SECTION(state) {
            state.form.area = ''
            state.form.departamento = ''
            state.form.primernivel = ''
            state.form.segundonivel = ''
            state.form.tercernivel = ''
            state.form.cuartonivel = ''
            state.form.precioMaximo = 300
            //
            state.area = []
            state.departamento = []
            state.primernivel = []
            state.segundonivel = []
            state.tercernivel = []
            state.cuartonivel = []
        },
    },
    actions: {
        fetchSeleccionadorInputs({commit, state}) {
            if(state.form.estado == '' || state.form.ciudad == '') {
                return
            }
            let params = {}
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['precioMaximo'] = state.form.precioMaximo
            params['area'] = state.form.area && state.form.area !== '' ? state.form.area : 'Ninguno';
            params['departamento'] = state.form.departamento && state.form.departamento !== '' ? state.form.departamento : 'Ninguno';
            params['primernivel'] = state.form.primernivel && state.form.primernivel !== '' ? state.form.primernivel : 'Ninguno';
            params['segundonivel'] = state.form.segundonivel && state.form.segundonivel !== '' ? state.form.segundonivel : 'Ninguno';
            params['tercernivel'] = state.form.tercernivel && state.form.tercernivel !== '' ? state.form.tercernivel : 'Ninguno';
            params['cuartonivel'] = state.form.cuartonivel && state.form.cuartonivel !== '' ? state.form.cuartonivel : 'Ninguno';
            state.inputsDataLoading = true
            interfazCatService
                .fetchInputsPorMenosDe(params)
                .then((response)=> {
                    const {area, departamento, primernivel, segundonivel, tercernivel, cuartonivel} = response.data.Filtros;
                    commit('SET_AREA', area)
                    commit('SET_DEPARTAMENTO', departamento) 
                    commit('SET_PRIMERNIVEL', primernivel)        
                    commit('SET_SEGUNDONIVEL', segundonivel)
                    commit('SET_TERCERNIVEL', tercernivel)
                    commit('SET_CUARTONIVEL', cuartonivel)
                })
                .catch((error)=>{
                    console.log("Error fetching inputs por menos de :(");
                })    
                .finally(() => {
                    state.inputsDataLoading = false;
                })
        },
        submitForm({commit, state}, payload) {
            if(state.form.estado == '' || state.form.ciudad == '') {
                return
            }
            let params = {}
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['precioMaximo'] = state.form.precioMaximo
            params['area'] = state.form.area && state.form.area !== '' ? state.form.area : 'Ninguno';
            params['departamento'] = state.form.departamento && state.form.departamento !== '' ? state.form.departamento : 'Ninguno';
            params['primernivel'] = state.form.primernivel && state.form.primernivel !== '' ? state.form.primernivel : 'Ninguno';
            params['segundonivel'] = state.form.segundonivel && state.form.segundonivel !== '' ? state.form.segundonivel : 'Ninguno';
            params['tercernivel'] = state.form.tercernivel && state.form.tercernivel !== '' ? state.form.tercernivel : 'Ninguno';
            params['cuartonivel'] = state.form.cuartonivel && state.form.cuartonivel !== '' ? state.form.cuartonivel : 'Ninguno';
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormPorMenosDe(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    let {Top_Compras = [], Top_Vistas = [], Top_Busquedas = []} = response.data.recomendaciones
                    let recomendaciones = [...Top_Compras, ...Top_Busquedas, ...Top_Vistas].map(item => ({...item, isSelectedForComparison: false, shortenedLink: null}))
                    commit('SET_PRODUCTS_LIST', recomendaciones);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    if (payload !== undefined && typeof payload.callback === 'function') {
                        payload.callback();
                    }
                })
        },
        resetSeleccionador(context) {
            context.commit('RESET_SECTION')
        },
        cleanForm(context) {
            context.commit('CLEAN_FORM')
        },
        //
        shortenLink({state, commit}, payload) {
            const items = state.productsList //
            const index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        commit('SET_PRODUCTS_LIST', items) // recomendaciones   
                    }
                })
                .finally(()=> {
                    this.shortenLinkLoading;
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    // getters: {}
}