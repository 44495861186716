import axios from 'axios';
import {merge} from 'lodash';

export default class BackendService {

	constructor() {
		this.http = axios;
		this.path = '';
		// this.token = document.head.querySelector('meta[name="csrf-token"]');
		// this.http.defaults.headers.common['X-CSRF-TOKEN'] = this.token.content;

		if (process.env.NODE_ENV === 'production') {
			this.apiUrl = 'https://management.coppelomnicanal.com/';
			//this.apiUrl = 'https://management-dot-ecommerce-bi.appspot.com/';
			// this.apiUrl = 'http://35.226.56.190:8000/';
			// this.apiUrl = 'http://127.0.0.1:8000/';
		} else {

			// this.apiUrl = 'https://management.coppelomnicanal.com/';

			// this.apiUrl = 'http://10.34.86.31:8000/';
			// this.apiUrl = 'http://127.0.0.1:8000/';
			this.apiUrl = 'http://localhost:8000/';

		}
	}

	httpCall(url, data) {

		let defaults = {
			url: this.apiUrl + url,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		};

		let mergeData = merge(defaults, data);

		return this.http(mergeData);

	}

}
