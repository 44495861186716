
/**
 * Verifica si el usuario actual tiene permisos para acceder a la ruta actual
 * 
 * @param {array} route_records
 * @return {boolean}
 */
export function checkUserRouteAuthorization( route_records ) {

	let canIGoThrough = true;

	const check_route = route_records.some( record => record.meta.authorize );

	// si el registro de ruta no tiene el meta authorize no es necesario verificar permisos
	if(!check_route) return canIGoThrough;

	let sessionapp = localStorage.getItem('sessionapp') || null;

	if (sessionapp) {

		sessionapp = JSON.parse(sessionapp);

		const { permissions: userPermissions } = sessionapp; // array con permissos del usuario
		
		// Verificar accesos
		canIGoThrough = route_records.some( record => record.meta.authorize && record.meta.authorize.some( r => userPermissions.includes( r ) ));

	} else {
		// borrar de localstorage todo lo referente a la session actual
	}

    return canIGoThrough;

}