
import RecomendadorCatEndpointsService from '../../services/RecomendadorCatEndpointsService'
const recomendadorCatEndpointsService = new RecomendadorCatEndpointsService();
import ShortenUrlsService from '../../services/ShortenUrlsService'
const shortenUrlsService = new ShortenUrlsService()

export default {
    namespaced: true,
    state: { 
        // 
        productsList: [],
        productsListLoading: false,
        productsComparadorList: [],
        form: {
            Nivel: '',
            //extras
            precioMaximo:  0,
            orden: 'Ninguno',
            estado: '',
            ciudad: '',
        },
        // computo
        computoNivel: [],
        computoInputsDataLoading: false,
        precioMaximo: 51000
    },
    mutations: {
        SET_PRODUCTS_LIST(state, items) {
            state.productsList = items;
        },
        SET_PRODUCTS_COMPARADOR_LIST(state, items) {
            state.productsComparadorList = items.slice(0,3);
        },
        // Computo
        SET_COMPUTO_NIVEL(state, data) {
            let items = []
            if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
                items = Object.values(data).filter(i => i != '-').sort()
            }
            state.computoNivel = items;
        },
        CLEAN_FORM(state) {
            state.form.Nivel = ''
            state.form.precioMaximo =  0
            state.form.orden = 'Ninguno'
            // state.form.estado = ''
            // state.form.ciudad = ''
        },
        RESET_SECTION(state) {
            state.form.Nivel = ''
            state.form.precioMaximo =  0
            state.form.estado = ''
            state.form.ciudad = ''
            state.form.orden = 'Ninguno'
            state.productsList = [];
            state.productsComparadorList = [];
            state.computoNivel = [];
        },
    },
    actions: {
        // computo
        fetchSeleccionadorInputsComputo({commit, state}) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['filters'] = 'None' 
            state.computoInputsDataLoading = true
            recomendadorCatEndpointsService
                .fetchSeleccionadorInputsComputo(params)
                .then((response)=> {
                    const niveles = response.data.filtros.Nivel;
                    commit('SET_COMPUTO_NIVEL', niveles)
                })
                .catch((error)=>{
                    console.log("Error fetching inputs seleccionador productos computo data :(");
                })    
                .finally(() => {
                    state.computoInputsDataLoading = false;
                })
        },
        //
        submitForm({commit, state}, callback) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            let paramsList = []
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value.length && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    paramsList.push(`${key}=${value}`)
                }
            });
            params['filters'] = paramsList.length > 0 ? paramsList.join('&') : 'None' 
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['orden'] = state.form.orden
            params['precioMaximo'] = state.form.precioMaximo > 0 ? state.form.precioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            recomendadorCatEndpointsService.submitFormComputo(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        updateProductsList__isSelectedForComparison({state, commit}) {
            const skusComparadorList = state.productsComparadorList.map(item => item.SKU);
            let itemsList = state.productsList;
            itemsList = itemsList.map(item => {
                let status = skusComparadorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
            commit('SET_PRODUCTS_LIST', itemsList);
        },
        addIProductToCompareList({state, commit, dispatch}, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = state.productsComparadorList;
                let someItem = items.findIndex(i => i.SKU == payload.SKU);
                if(someItem == -1){
                    items.unshift(payload)
                    commit('SET_PRODUCTS_COMPARADOR_LIST', items.slice(0,3))
                }
                dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        removeProductFromCompareList(context, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = context.state.productsComparadorList;
                let filtered = items.filter(i => i.SKU !== payload.SKU);
                if(filtered){
                    context.commit('SET_PRODUCTS_COMPARADOR_LIST', filtered.slice(0,3));
                }
                context.dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        cleanCompareList(context, payload) {
            context.commit('SET_PRODUCTS_COMPARADOR_LIST', []);
        },
        resetComputoSection(context) {
            context.commit('RESET_SECTION')
        },
        cleanForm(context) {
            context.commit('CLEAN_FORM')
        },
        //
        shortenLink({state, commit}, payload) {
            const items = state.productsList //
            const index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        commit('SET_PRODUCTS_LIST', items) // recomendaciones   
                    }
                })
                .finally(()=> {
                    this.shortenLinkLoading;
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    getters: {
    }
}