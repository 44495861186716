import RecomendadorCatEndpointsService from '../../services/RecomendadorCatEndpointsService'
import ShortenUrlsService from '../../services/ShortenUrlsService'
const interfazCatService = new RecomendadorCatEndpointsService();
const shortenUrlsService = new ShortenUrlsService()

export default {
    namespaced: true,
    state: { 
        // 
        productsList: [],
        productsListLoading: false,
        productsComparadorList: [],
        form: {
            des_Familia: '',
            //extras
            precioMaximo:  0,
            orden: 'Ninguno',
            estado: '',
            ciudad: '',
        },
        
        des_Familia: [],
        precioMaximo: 86000,
        inputsDataLoading: false
    },
    mutations: {
        SET_PRODUCTS_LIST(state, items) {
            state.productsList = items.slice(0,20);
        },
        SET_PRODUCTS_COMPARADOR_LIST(state, items) {
            state.productsComparadorList = items.slice(0,3);
        },
        //
        SET_DES_FAMILIA(state, data){
            state.des_Familia = Object.values(data);
        },
        //
        CLEAN_FORM(state) {
            state.form.des_Familia = '';
            state.form.precioMaximo =  0
            state.form.orden = 'Ninguno'
        },
        RESET_SECTION(state) {
            state.form.des_Familia = '';
            state.form.orden = 'Ninguno'
            state.form.precioMaximo =  0
            //
            state.productsList = [];
            state.productsComparadorList = [];
            state.des_Familia = [];
        },
    },
    actions: {
        fetchSeleccionadorInputs({commit, state}) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            var filters = []
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value.length > 0 && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            state.inputsDataLoading = true
            interfazCatService
                .fetchInputsSeleccionadorMotocicletas(params)
                .then((response)=> {
                    const {des_Familia} = response.data.filtros;
                    commit('SET_DES_FAMILIA', des_Familia)           
                })
                .catch((error)=>{
                    console.log("Error fetching inputs motocicletas :(");
                })    
                .finally(() => {
                    state.inputsDataLoading = false;
                })
        },
        submitForm({commit, state}, callback) {
            if(!state.form.estado || !state.form.ciudad) {
                return
            }
            let params = {}
            let paramsList = []
            Object.entries(state.form).forEach(([key, value]) => {
                if(value != undefined && value.length && key !== 'precioMaximo' && key !== 'estado' && key !== 'ciudad' && key != 'orden') {
                    paramsList.push(`${key}=${value}`)
                }
            });
            params['filters'] = paramsList.length > 0 ? paramsList.join('&') : 'None' 
            params['estado'] = state.form.estado
            params['ciudad'] = state.form.ciudad
            params['orden'] = state.form.orden
            params['precioMaximo'] = state.form.precioMaximo > 0 ? state.form.precioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormMotocicletas(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }

                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        updateProductsList__isSelectedForComparison({state, commit}) {
            const skusComparadorList = state.productsComparadorList.map(item => item.SKU);
            // productsList
            let itemsList = state.productsList;
            itemsList = itemsList.map(item => {
                let status = skusComparadorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
            commit('SET_PRODUCTS_LIST', itemsList);
        },
        addIProductToCompareList({state, commit, dispatch}, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = state.productsComparadorList;
                let someItem = items.findIndex(i => i.SKU == payload.SKU);
                if(someItem == -1){
                    items.unshift(payload)
                    commit('SET_PRODUCTS_COMPARADOR_LIST', items.slice(0,3))
                }
                dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        removeProductFromCompareList(context, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = context.state.productsComparadorList;
                let filtered = items.filter(i => i.SKU !== payload.SKU);
                if(filtered){
                    context.commit('SET_PRODUCTS_COMPARADOR_LIST', filtered.slice(0,3));
                }
                context.dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        cleanCompareList(context, payload) {
            context.commit('SET_PRODUCTS_COMPARADOR_LIST', []);
        },
        resetSeleccionador(context) {
            context.commit('RESET_SECTION')
        },
        cleanForm(context) {
            context.commit('CLEAN_FORM')
        },
        //
        shortenLink({state, commit}, payload) {
            const items = state.productsList //
            const index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        commit('SET_PRODUCTS_LIST', items) // recomendaciones   
                    }
                })
                .finally(()=> {
                    this.shortenLinkLoading;
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    // getters: {}
}