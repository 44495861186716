
export default {
    namespaced: true,
    state: { 
        productsComparatorList: [],
    },
    mutations: {
        SET_PRODUCTS_COMPARATOR_LIST(state, items) {
            state.productsComparatorList = items.slice(0,3);
        },
    },
    actions: {
        addProductToComparator({state, commit}, product) {
            let products = state.productsComparatorList
            let indexProduct = products.findIndex(i => i.SKU == product.SKU);
            if(indexProduct == -1) {
                product.isSelectedForComparison = true;
                products.unshift(product)
                commit('SET_PRODUCTS_COMPARATOR_LIST', products)
            }
        },
        removeProductFromComparator({state, commit}, product) {
            let items = state.productsComparatorList;
            let filteredItems = items.filter(i => i.SKU !== product.SKU);
            if(filteredItems){
                commit('SET_PRODUCTS_COMPARATOR_LIST', filteredItems)
            }
        },
        cleanProductsComparator(context) {
            context.commit('SET_PRODUCTS_COMPARATOR_LIST', []);
        },
    },
}