import axios from 'axios';
import {merge} from 'lodash';

export default class BackendAuthService {

	constructor() {
		this.http = axios.create();
		this.path = '';
		this.token = '';
		this.setAuthorizationHeader()
		this.http.defaults.headers.common['Content-Type'] = "multipart/form-data"

		

		if (process.env.NODE_ENV === 'production') {
			this.apiUrl = 'https://management.coppelomnicanal.com/';
			//this.apiUrl = 'https://management-dot-ecommerce-bi.appspot.com/';
			// this.apiUrl = 'http://35.226.56.190:8000/';
			//this.apiUrl = 'http://127.0.0.1:8000/';

		} else {
			// this.apiUrl = 'https://manageement.coppelomnicanal.com/';
			// this.apiUrl = 'http://10.34.86.31:8000/';

			// this.apiUrl = 'http://127.0.0.1:8000/';
			this.apiUrl = 'http://localhost:8000/';

		}
	}

	setAuthorizationHeader() {
		if(this.token == null || this.token == '') {
			this.token =  localStorage.getItem('access');
			this.http.defaults.headers.common['Authorization'] = " Bearer " + this.token
		}
	}

	httpCall(url, data) {

		let defaults = {
			url: this.apiUrl + url,
			// method: 'GET',
		};

		let mergeData = merge(defaults, data);

		// cargar token de autenticacón en cabeceras
		// para evitar bug Bearer null al logearse y no recargar la página
		this.setAuthorizationHeader();

		return this.http(mergeData);

	}

}
