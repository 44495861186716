import BackendService from "./BackendService";

export default class PimService extends BackendService {

	constructor() {
		super();
	}

	login(data){
		return this.httpCall('api/token/', {
			method: 'POST',
			data: data
		});
	}

	verify(data) {
		return this.httpCall('api/token/verify/', {
			method: 'post',
			data: data,
		});
	}
	refresh() {
		return this.httpCall('api/token/refresh/', {
			method: 'POST'
		});
	}

	resetPassword(data) {
		return this.httpCall('api/auth/users/reset_password/', {
			method: 'post',
			data: data,
		});
	}

	resetPasswordConfirm(data) {
		return this.httpCall('api/auth/users/reset_password_confirm/', {
			method: 'post',
			data: data,
		});
	}


}

