import './gtm'
import Vue from 'vue'
import App from './App.vue'
import './plugins/base'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify';
import VeeValidate from './plugins/vee-validate';
import Helpers from './plugins/helpers';
import './assets/css/global.css'

import './assets/css/rangeSlider.css'
import './assets/css/rangeSliderSkin.css'
import './assets/js/rangeSlider.js'
// import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(Helpers);

new Vue({
  router,
  store,
  vuetify,
  VeeValidate,
  render: h => h(App)
}).$mount('#app')
