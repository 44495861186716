import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import VueI18n from 'vue-i18n'

import MultiFiltersPlugin from './MultiFilters';
Vue.use(MultiFiltersPlugin);

// Translation provided by Vuetify (javascript)
import es from 'vuetify/es5/locale/es'

//import '@/sass/overrides.sass'


const theme = {

	'blue-400': "#EBF4FA",
	'blue-500': "#006FB9",
	'blue-600': "#00548C",
	'blue-700': "#003960",
	'blue-800': "#002842",

	'yellow-300' : 'f1c232', // color text ru form
	'yellow-400' : '#FFFDEF',
	'yellow-500' : '#FFDD30',
	'yellow-600' : '#EEC700',
	'yellow-700' : '#BFA023',
	'yellow-800' : '#806B18',
	'yellow-900' : '#40340C',

	'ui-100' : '#FFFFFF',
	'ui-200' : '#f3f3f3',
	'ui-300' : '#F6F6F8',
	'ui-400' : '#EDEEF2',
	'ui-500' : '#C6C7CB',
	'ui-600' : '#68686A',
	'ui-700' : '#212222',
}

Vue.use(Vuetify);
// Vue.use(VueI18n);


// const i18n = new VueI18n({
// 	locale: 'es', // set locale
// 	messages, // set locale messages
// })
export default new Vuetify({
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'mdi',
	},
	
	theme: {
		themes: {
			dark: theme,
			light: theme
		}
	}
});
