let initState = { 
    user: {
        name: '',
        username: '',
        area: '',
        email: '',
        noEmpleado: '',
        tipoVenta: '',
    }, 
    permissions: [],
    token: null, 
    refresh: null
};

let sessionapp = localStorage.getItem('sessionapp') ? JSON.parse(localStorage.getItem('sessionapp')) : initState;

export default {
    namespaced: true,
    state: { 
        ...sessionapp
    },
    actions: {
        session({ commit }, data) {
            // Recoger datos a traves del token, reempzado por /v1/api/me
            // let payload = JSON.parse( atob(  data.token.split('.')[1]  ) );
            // let user = { // user data
            //     name: payload.fullname,
            //     username: payload.username,
            //     email: payload.email,
            //     area: payload.area,
            // }
            // session data
            // let sessionapp = {
            //     token: data.token,
            //     refresh: data.refresh
            // }
            const sessionapp = Object.assign({}, initState);
            sessionapp.token = data.token
            sessionapp.refresh = data.refresh
            // storage session data
			localStorage.setItem('sessionapp', JSON.stringify(sessionapp))
        },
        me({ commit }, payload) {
            let sessionapp = JSON.parse(localStorage.getItem('sessionapp'));
            sessionapp.permissions = payload.permissions;
            sessionapp.user.name = payload.user.fullname;
            sessionapp.user.username = payload.user.username;
            sessionapp.user.area = payload.user.area;
            sessionapp.user.email = '';
            sessionapp.user.noEmpleado = payload.user.no_empleado;
            sessionapp.user.tipoVenta = payload.user.tipo_venta;
            localStorage.setItem('sessionapp', JSON.stringify(sessionapp))
            commit('SET_PERMISSIONS', payload)
            commit('SET_SESSION_INFO', sessionapp )
        },
        deleteSession({ commit }) {
            // clear local storage
            localStorage.clear();
            commit('DESTROY_SESSION')
        }
    },
    getters: {
        userName(state) {
            let username = state.user.name.trim();
            return username ? username : state.user.username;
        },
        userNameAvatar( state, getters) {
            let userArr = getters.userName.trim().split(' ');
            let username = userArr.length == 1 ? userArr[0].substring(0,2) : userArr[0].charAt(0) + userArr[1].charAt(0);
            return  username.toUpperCase();
        },
        userArea(state){
            return state.user.area;
        },
        userNoEmpleado(state) {
            return state.user.noEmpleado;
        },
        userTipoVenta(state) {
            return state.user.tipoVenta;
        },
        email(state){
            return state.user.email;
        },
        permissions( state ) {
            const { permissions } = state;
            return permissions;
        }
    },
    mutations: {
        SET_PERMISSIONS(state, payload) {
            state.permissions = payload.permissions;
        },
        SET_SESSION_INFO(state, payload) {
            state.user = payload.user;
            state.token = payload.token;
            state.refresh = payload.refresh;
        },
        DESTROY_SESSION( state ) {
            state.user = initState.user;
            state.token = initState.token;
            state.refresh = initState.token;
            state.permissions = initState.permissions;
        }
    }
}