import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
var axios = require('axios');

import Users from './services/Users';
const users = new Users();


// traer los grupos del back y ponerlos en un estado global
// import groups from './roles';
import { checkUserRouteAuthorization } from './utils/authorization'

const router = new Router({ 
	// mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{ path: '*',redirect: '/' },
		{
			path: '/',
			component: () => import('@/views/Index'),
			children: [
				{ path: '/',	name: 'login', 		component: ()=>import('@/views/Login') },
				{
					path: '/reset-password',
					name: 'reset_password',
					component: ()=>import('@/views/ResetPassword')
				},
				{
					path: '/password/reset/confirm/:uid/:token',
					name: 'reset_password_confirm',
					component: ()=>import('@/views/ResetPasswordConfirm')
				}
				
			]
			
		},
		{
			path: '/dashboard',
			meta: { requiresAuth: true },
			component: () => import('@/views/dashboard/Index'),
			children: [
				{ 
					path: '/',			
					name: 'home',			
					component: () => import('@/views/dashboard/Analytics'), 
					meta: {
						authorize: [ 'view_analitica_digital' ]
					}
				},
				{ 
					path: '/changelog',	
					name: 'changelog',		
					component: () => import('@/views/dashboard/changelog'),
					meta: {
						authorize: [ 'view_versiones' ]
					},
				},
				{ 
					path: '/existencia',	
					name: 'existencia',		
					component: ()=>import('@/views/dashboard/existencia'),
					meta: {
						authorize: [ 'view_alerta_existencia' ]
					}
				},
				{ 
					path: '/logs',		
					name: 'logs',			
					component: ()=>import('@/views/dashboard/Logs'),
					meta: {
						authorize: [ 'view_pipeline_logs' ]
					}
				},
				{ 
					path: '/form-logs',		
					name: 'form_logs',			
					component: ()=>import('@/views/dashboard/FormLogs'),
					meta: {
						authorize: [ 'view_forms_logs' ]
					}
				},
				{   
					path: '/panel',		
					name: 'panel',		
					component: () => import('@/views/dashboard/Inicio'),
					
				},
				{ 
					path: '/segmentador/:segment?/:item?',	
					name: 'segmentador',	
					component: () => import('@/views/dashboard/Segmentador'),
					meta: {
						authorize: [ 'view_segmentaciones' ]
					}
				},
				{ 
					path: '/casos-de-uso',	
					name: 'casosdeuso',	
					component: () => import('@/views/dashboard/casosdeuso/Index'),
					meta: {
						authorize: [ 'view_form_casosdeuso' ]
					}
				},
				{ 
					path: '/formulario-recomendador-universal',	
					name: 'ruform',	
					component: () => import('@/views/dashboard/ruform/Index'),
					meta: {
						authorize: [ 'view_form_ru' ]
					}
				},
				{ 
					path: '/segtmentos',	
					name: 'ruform_segments',	
					component: () => import('@/views/dashboard/ruform/views/Segments'),
				},
				{ 
					path: '/pim',			
					name: 'pim',			

					component: () => import('@/views/dashboard/Pim'),
					meta: {
						authorize: [ 'view_pim' ]
					}
				},
				{ 
					path: '/score-pdp',			
					name: 'score_pdp',			
					component: () => import('@/views/dashboard/ScorePDP'),
					meta: {
						authorize: [ 'view_scorespdp' ]
					}
				},
				{ 
					path: '/porcentaje-completitud',			
					name: 'porcentaje_completitud',			
					component: () => import('@/views/dashboard/porcentajecompletitud'),
					meta: {
						authorize: [ 'view_porcentaje_completitud' ]
					}
				},
				{ 
					path: '/replenishment',name: 'replenishment',			
					component: () => import('@/views/dashboard/Replenishment'),
					meta: {
						authorize: [ 'view_mascotas' ]
					}
				},
				{ 
					path: '/ranking',			
					name: 'ranking',			
					component: () => import('@/views/dashboard/Ranking'),
					meta: {
						authorize: [ 'view_ranking_resultados' ]
					}
				},
				{ 
					path: '/caracola',			
					name: 'caracola',			
					component: () => import('@/views/dashboard/caracola'),
					meta: {
						authorize: [ 'view_caracola' ]
					}
				},
				// { 
				// 	path: '/interfaz-cat',			
				// 	name: 'interfaz_cat',			
				// 	component: () => import('@/views/dashboard/interfazcat'),
				// 	meta: {
				// 		authorize: [ 'view_interfaz_cat' ]
				// 	}
				// },
				{ 
					path: '/recomendador-cat',			
					name: 'recomendador_cat',			
					component: () => import('@/views/dashboard/recomendadorcat'),
					meta: {
						authorize: [ 'view_interfaz_cat_2' ] //
					}
				},
				{ 
					path: '/acortador-urls',			
					name: 'acortador_urls',			
					component: () => import('@/views/dashboard/acortadorurls'),
					//meta: {
					//	authorize: [ 'view_interfaz_cat' ]
					//}
				},
				{ 
					path: '/motor-audiencias',			
					name: 'motor_audiencias',			
					component: () => import('@/views/dashboard/motoraudiencias'),
					meta: {
						authorize: [ 'view_motor_audiencias' ]
					}
				},
				{ 
					path: '/buzon-de-mensajes',			
					name: 'buzon_mensajes',			
					component: () => import('@/views/dashboard/buzon'),
					meta: {
						authorize: [ 'view_buzon_de_mesajes' ]
					}
				},
			]
		},
	]
})

router.beforeEach((to, from, next) => {
	
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	let token =  localStorage.getItem('access');
	let refresh =  localStorage.getItem('refresh');
	let time =  localStorage.getItem('time');

	// checar si se tienen los permisos para el actual registro de ruta
	var amIauthorizedForCurrentRoute = checkUserRouteAuthorization(to.matched);

	let data= {
		'token': token
	}

	let currentUser = false;
	//let now = new Date();
	//now  = now.getTime();

	users.verify(data)
	.then(response=>{
		currentUser = true;
	})
	.catch(error =>{

	})
	.then(response=>{
		if (requiresAuth && !currentUser) next('login');
		// permisos para la actual ruta
		else if ( !amIauthorizedForCurrentRoute ) next('panel');
		else if (!requiresAuth && currentUser) next('panel');
		else next();
	})


});
export default router;