import Vue from 'vue'
import {
  extend,
  setInteractionMode,
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import { required, alpha, email, ext } from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'El campo {_field_} es requerido'
})

extend('email', {
  ...email,
  message: 'El campo {_field_} debe ser una dirección de e-mail válida'
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Las contraseñas no coinciden'
});

extend('alphabetNumersUnderScore', {
  validate: value => {
    return /^[A-Za-z0-9_]+$/.test(value);
  },
  message: "Solo se permiten las letras del alfabeto, los números y el guion bajo (_)"
})

extend('url', {
  validate: value => {
    if(value) {
      try {
        new URL(value);
        return true
      } catch(error) {
          return false
      }
    }
    return false
    //return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
  },
  message: 'This value must be a valid URL',
})

extend('ext', {
  ...ext
})

extend('validate_MM_YYYY', value => {
  const regex = /^((0[1-9]|1[0-2]) (20[0-9]{2})(, )?)+$/
  return regex.test(value);
});


Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
