import RecomendadorCatEndpointsService from '../../services/RecomendadorCatEndpointsService'
import ShortenUrlsService from '../../services/ShortenUrlsService'
const interfazCatService = new RecomendadorCatEndpointsService();
const shortenUrlsService = new ShortenUrlsService()

export default {
    namespaced: true,
    state: { 
        // 
        productsList: [],
        productsListLoading: false,
        productsComparadorList: [],
        formAiac: {
            marca: '',
            metros_cuadrados: '',
            inverter: '',
            voltaje_operacion: '',
            conectividad: ''
        },
        formCalef: {
            tipo_calefactor: '',
            marca: '',
            medidas_largo_x_ancho_x_alto: ''
        },
        formCoolers: {
            tipo_producto: '',
            marca: '',
            capacidad_agua: '',
            potencia: ''
        },
        //extra fiels forms
        formPrecioMaximo:  0,
        orden: 'Ninguno',
        estado: '',
        ciudad: '',
        // inputs values'
        precioMaximo: 57000,
        // AIAC
        aiacMarcas: [],
        aiacMetrosCuadrados: [],
        aiacInverters: [],
        aiacVoltajeOperacion: [],
        aiacConectividad: [],
        aiacInputsDataLoading: false,
        // calef
        calefTiposCalefactor: [],
        calefMarcas: [],
        calefMedidas: [],
        calefInputsDataLoading: false,
        // Colers
        coolerTiposProducto: [],
        coolerMarcas: [],
        coolerCapacidadAgua: [],
        coolerPotencia: [],
        coolerInputsDataLoading: false,
        //
    },
    mutations: {
        SET_PRODUCTS_LIST(state, data) {
            state.productsList = data;
        },
        SET_PRODUCTS_COMPARADOR_LIST(state, items) {
            state.productsComparadorList = items.slice(0,3);
        },
        //
        SET_AIAC_MARCAS(state, data) {
            state.aiacMarcas = Object.values(data);;
        },
        SET_AIAC_METROS_CUADRADOS(state, data) {
            state.aiacMetrosCuadrados = Object.values(data);;
        },
        SET_AIAC_INVERTERS(state, data) {
            state.aiacInverters = Object.values(data);;
        },
        SET_AIAC_VOLTAJE_OPERACION(state, data) {
            state.aiacVoltajeOperacion = Object.values(data);;
        },
        SET_AIAC_CONECTIVIDAD(state, data) {
            state.aiacConectividad = Object.values(data);;
        },
        //
        SET_COOLER_TIPOS_PRODUCTO(state, data) {
            state.coolerTiposProducto = Object.values(data);;
        },
        SET_COOLER_MARCAS(state, data) {
            state.coolerMarcas = Object.values(data);;
        },
        SET_COOLER_CAPACIDAD_AGUA(state, data) {
            state.coolerCapacidadAgua = Object.values(data);;
        },
        SET_COOLER_POTENCIA(state, data) {
            state.coolerPotencia = Object.values(data);;
        },
        //
        SET_CALEF_TIPOS_CALEFACTOR(state, data) {
            state.calefTiposCalefactor = Object.values(data);;
        },
        SET_CALEF_MARCAS(state, data) {
            state.calefMarcas = Object.values(data);;
        },
        SET_CALEF_MEDIDAS(state, data) {
            state.calefMedidas = Object.values(data);;
        },
        CLEAN_FORM(state) {
            state.formAiac.marca = ''
            state.formAiac.metros_cuadrados = ''
            state.formAiac.inverter = ''
            state.formAiac.voltaje_operacion =  ''
            state.formAiac.conectividad = ''
            state.formCalef.tipo_calefactor = ''
            state.formCalef.marca = ''
            state.formCalef.medidas_largo_x_ancho_x_alto = ''
            state.formCoolers.tipo_producto = ''
            state.formCoolers.marca = ''
            state.formCoolers.capacidad_agua = ''
            state.formCoolers.potencia = ''
            state.formPrecioMaximo =  0
            state.orden = 'Ninguno'
        },
        RESET_SECTION(state) {
            state.formAiac.marca = ''
            state.formAiac.metros_cuadrados = ''
            state.formAiac.inverter = ''
            state.formAiac.voltaje_operacion =  ''
            state.formAiac.conectividad = ''
            state.formCalef.tipo_calefactor = ''
            state.formCalef.marca = ''
            state.formCalef.medidas_largo_x_ancho_x_alto = ''
            state.formCoolers.tipo_producto = ''
            state.formCoolers.marca = ''
            state.formCoolers.capacidad_agua = ''
            state.formCoolers.potencia = ''
            state.formPrecioMaximo =  0
            state.orden = 'Ninguno'
            //
            state.aiacMarcas = [];
            state.aiacMetrosCuadrados = [];
            state.aiacInverters = [];
            state.aiacVoltajeOperacion = [];
            state.aiacConectividad = [];
            // calef
            state.calefTiposCalefactor = [];
            state.calefMarcas = [];
            state.calefMedidas = [];
            // Colers
            state.coolerTiposProducto = [];
            state.coolerMarcas = [];
            state.coolerCapacidadAgua = [];
            state.coolerPotencia = [];
            state.productsList = [];
            state.productsComparadorList = [];
        },
    },
    actions: {
        fetchInputsSeleccionadorAiac({commit, state}) {
            if(!state.estado || !state.ciudad) {
                return
            }
            let params = {}
            var filters = []
            params['estado'] = state.estado
            params['ciudad'] = state.ciudad
            Object.entries(state.formAiac).forEach(([key, value]) => {
                if(value != undefined && value.length > 0) {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            state.aiacInputsDataLoading = true
            interfazCatService
                .fetchInputsSeleccionadorAiac(params)
                .then((response)=> {
                    const {marca, metros_cuadrados, inverter, voltaje_operacion, conectividad} = response.data.filtros;
                    commit('SET_AIAC_MARCAS', marca)
                    commit('SET_AIAC_METROS_CUADRADOS', metros_cuadrados)
                    commit('SET_AIAC_INVERTERS', inverter)
                    commit('SET_AIAC_VOLTAJE_OPERACION', voltaje_operacion)
                    commit('SET_AIAC_CONECTIVIDAD', conectividad)
                })
                .catch((error)=>{
                    console.log("Error fetching inputs aiac :(");
                })    
                .finally(() => {
                    state.aiacInputsDataLoading = false;
                })
        },
        fetchInputsSeleccionadorCalef({commit, state}) {
            if(!state.estado || !state.ciudad) {
                return
            }
            let params = {}
            var filters = []
            params['estado'] = state.estado
            params['ciudad'] = state.ciudad
            Object.entries(state.formCalef).forEach(([key, value]) => {
                if(value != undefined && value.length > 0) {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            state.calefInputsDataLoading = true
            interfazCatService
                .fetchInputsSeleccionadorCalef(params)
                .then((response)=> {
                    const {tipo_calefactor, marca, medidas_largo_x_ancho_x_alto} = response.data.filtros;
                    commit('SET_CALEF_TIPOS_CALEFACTOR', tipo_calefactor)
                    commit('SET_CALEF_MARCAS', marca)
                    commit('SET_CALEF_MEDIDAS', medidas_largo_x_ancho_x_alto)
                })
                .catch((error)=>{
                    console.log("Error fetching inputs calef :(");
                })    
                .finally(() => {
                    state.calefInputsDataLoading = false;
                })
        },
        fetchInputsSeleccionadorCoolers({commit, state}) {
            if(state.estado == '' || state.ciudad == '') {
                return
            }
            let params = {}
            var filters = []
            params['estado'] = state.estado
            params['ciudad'] = state.ciudad
            Object.entries(state.formCoolers).forEach(([key, value]) => {
                if(value != undefined && value.length > 0) {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            state.coolerInputsDataLoading = true
            interfazCatService
                .fetchInputsSeleccionadorCoolers(params)
                .then((response)=> {
                    const {tipo_producto, marca, capacidad_agua, potencia} = response.data.filtros;
                    commit('SET_COOLER_TIPOS_PRODUCTO', tipo_producto)
                    commit('SET_COOLER_MARCAS', marca)
                    commit('SET_COOLER_CAPACIDAD_AGUA', capacidad_agua)
                    commit('SET_COOLER_POTENCIA', potencia)
                })
                .catch((error)=>{
                    console.log("Error fetching inputs coolers :(");
                })    
                .finally(() => {
                    state.coolerInputsDataLoading = false;
                })
        },
        submitFormAiac({commit, state}, callback) {
            if(state.estado == '' || state.ciudad == '') {
                return
            }
            let params = {}
            let filters = []
            Object.entries(state.formAiac).forEach(([key, value]) => {
                if(value != undefined && value.length) {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            params['estado'] = state.estado
            params['ciudad'] = state.ciudad
            params['orden'] = state.orden
            params['precioMaximo'] = state.formPrecioMaximo > 0 ? state.formPrecioMaximo : state.precioMaximo
            state.productsListLoading = true;
            // console.log("los parametros", params)
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormAiac(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        submitFormCoolers({commit, state}, callback) {
            if(state.estado == '' || state.ciudad == '') {
                return
            }
            let params = {}
            let filters = []
            Object.entries(state.formCoolers).forEach(([key, value]) => {
                if(value != undefined && value.length) {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            params['estado'] = state.estado
            params['ciudad'] = state.ciudad
            params['orden'] = state.orden
            params['precioMaximo'] = state.formPrecioMaximo > 0 ? state.formPrecioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormCoolers(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        submitFormCalef({commit, state}, callback) {
            if(state.estado == '' || state.ciudad == '') {
                return
            }
            let params = {}
            let filters = []
            Object.entries(state.formCalef).forEach(([key, value]) => {
                if(value != undefined && value.length) {
                    filters.push(`${key}=${value}`)
                }
            });
            params['filters'] = filters.length > 0 ? filters.join('&') : 'None' 
            params['estado'] = state.estado
            params['ciudad'] = state.ciudad
            params['orden'] = state.orden
            params['precioMaximo'] = state.formPrecioMaximo > 0 ? state.formPrecioMaximo : state.precioMaximo
            state.productsListLoading = true;
            commit('SET_PRODUCTS_LIST', []);
            interfazCatService
                .submitFormCalef(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    const items = response.data.Recommendations_list.map(item => ({...item, isSelectedForComparison: false})).slice(0,20)
                    commit('SET_PRODUCTS_LIST', items);
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                    // console.log(error.message)
                })
                .finally(()=>{
                    state.productsListLoading = false;
                    callback();
                })
        },
        updateProductsList__isSelectedForComparison({state, commit}) {
            const skusComparadorList = state.productsComparadorList.map(item => item.SKU);
            let itemsList = state.productsList;
            itemsList = itemsList.map(item => {
                let status = skusComparadorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
            commit('SET_PRODUCTS_LIST', itemsList);
        },
        addIProductToCompareList({state, commit, dispatch}, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = state.productsComparadorList;
                let someItem = items.findIndex(i => i.SKU == payload.SKU);
                if(someItem == -1){
                    items.unshift(payload)
                    commit('SET_PRODUCTS_COMPARADOR_LIST', items.slice(0,3))
                }
                dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        removeProductFromCompareList(context, payload) {
            if(payload != undefined && typeof payload == "object") {
                let items = context.state.productsComparadorList;
                let filtered = items.filter(i => i.SKU !== payload.SKU);
                if(filtered){
                    context.commit('SET_PRODUCTS_COMPARADOR_LIST', filtered.slice(0,3));
                }
                context.dispatch('updateProductsList__isSelectedForComparison');
            }
        },
        cleanCompareList(context, payload) {
            context.commit('SET_PRODUCTS_COMPARADOR_LIST', []);
        },
        resetSeleccionador(context) {
            context.commit('RESET_SECTION')
        },
        cleanForm(context) {
            context.commit('CLEAN_FORM')
        },
        //
        shortenLink({state, commit}, payload) {
            const items = state.productsList //
            const index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        commit('SET_PRODUCTS_LIST', items) // recomendaciones   
                    }
                })
                .finally(()=> {
                    this.shortenLinkLoading;
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    // getters: {}
}