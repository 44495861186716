import Vue from 'vue'
import Vuex from 'vuex'

//modules
import session from './modules/session.module'
// import seleccionadorProductos from './modules/seleccionador_productos.module'
// import seleccionadorProductosComputo from './modules/seleccionador_productos__computo.module'
// import seleccionadorProductosBaterias from './modules/seleccionador_productos__baterias.module'
// import seleccionadorProductosClimaVentilacion from './modules/seleccionador_productos__clima_ventilacion.module'
// import seleccionadorProductosCalentadores from './modules/seleccionador_productos__calentadores.module'
// import seleccionadorProductosMotocicletas from './modules/seleccionador_productos__motocicletas.module'
// import seleccionadorProductosCelulares from './modules/seleccionador_productos__celuares.module'
// import seleccionadorProductosLavadoras from './modules/seleccionador_productos__lavadoras.module'
// import seleccionadorProductosColchones from './modules/seleccionador_productos__colchones.module'
// import seleccionadorProductosRefrigeradores from './modules/seleccionador_productos__refrigeradores.module'
// import seleccionadorProductosEstufas from './modules/seleccionador_productos__estufas.module'
// import seleccionadorProductosPantallas from './modules/seleccionador_productos__pantallas.module'
// import seleccionadorProductosSalas from './modules/seleccionador_productos__salas.module'
// import seleccionadorProductosCocinas from './modules/seleccionador_productos__cocinas.module'

import seleccionadorComputo from './modules/seleccionador_computo.module'
import seleccionadorLlantas from './modules/seleccionador_llantas.module'
import seleccionadorBaterias from './modules/seleccionador_baterias.module'
import seleccionadorClimaVentilacion from './modules/seleccionador_clima_ventilacion.module'
import seleccionadorCalentadores from './modules/seleccionador_calentadores.module'
import seleccionadorMotocicletas from './modules/seleccionador_motocicletas.module'
import seleccionadorPorMenosDe from './modules/seleccionador_pormenosde.module'
import tablaComparativaProductos from './modules/tabla_comparativa_productos.module'
import seleccionadorLavadoras from './modules/seleccionador_lavadoras.module'
import seleccionadorRefrigeradores from './modules/seleccionador_refrigeradores.module'
import seleccionadorColchones from './modules/seleccionador_colchones.module'
import seleccionadorPantallas from './modules/seleccionador_pantallas.module'
import seleccionadorSalas from './modules/seleccionador_salas.module'
import seleccionadorCelulares from './modules/seleccionador_celulares.module'
import seleccionadorEstufas from './modules/seleccionador_estufas.module'
import seleccionadorCocinas from './modules/seleccionador_cocinas.module'




Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        session,
		// seleccionadorProductos,
		// seleccionadorProductosComputo,
		// seleccionadorProductosBaterias,
		// seleccionadorProductosClimaVentilacion,
		// seleccionadorProductosCalentadores,
		// seleccionadorProductosMotocicletas,
		// seleccionadorProductosCelulares,
		// seleccionadorProductosLavadoras,
		// seleccionadorProductosColchones,
		// seleccionadorProductosRefrigeradores,
		// seleccionadorProductosEstufas,
		// seleccionadorProductosPantallas,
		// seleccionadorProductosSalas,
		// seleccionadorProductosCocinas,

		seleccionadorComputo,
		seleccionadorLlantas,
		seleccionadorBaterias,
		seleccionadorClimaVentilacion,
		seleccionadorCalentadores,
		seleccionadorMotocicletas,
		seleccionadorPorMenosDe,
		tablaComparativaProductos,
		seleccionadorLavadoras,
		seleccionadorRefrigeradores,
		seleccionadorColchones,
		seleccionadorPantallas,
		seleccionadorSalas,
		seleccionadorCelulares,
		seleccionadorEstufas,
		seleccionadorCocinas
    },
	state: {
		step1_data:{},
		step2_data:{},
		step3_data:{},
		step4_data:{},
		// for some validation purpose
		steps_state: {
			step1: false,
			step2: false
		}
	},
	actions: {
		step1_data: ({ commit},payload) => {
			// console.log(payload)
			commit("step1_data", payload);
		},
		step2_data: ({ commit},payload) => {
			// console.log(payload)
			commit("step2_data", payload);
		},
		step3_data: ({ commit},payload) => {
			// console.log(payload)
			commit("step3_data", payload);
		},
		step4_data: ({ commit},payload) => {
			// console.log(payload)
			commit("step4_data", payload);
		},
		update_step_state: ({ commit }, payload) => {
			commit("update_step_state", payload)
		},
		segmenterClear({commit}) {
			commit('CLEAR')
		}
	},
	getters: {
		step1: (state) => {
			return state.step1_data;
		},
		step2: (state) => {
			return state.step2_data;
		},
		step3: (state) => {
			return state.step3_data;
		},
		step4: (state) => {
			return state.step4_data;
		},
		stepsState: (state) => {
			return state.steps_state;
		},
		canIGoToStep3: (state) => {
			if ( state.steps_state.step1 || state.steps_state.step2 ) {
				return true;
			}
			return false;
		},
		isGoogleAdsSupported: (state) => {
			const UNSUPPORTED = ["comprasdetalle", "baseBuscadorApp"]
			return state.step1_data != undefined && !UNSUPPORTED.includes(state.step1_data.segment)
		}
	},
	mutations: {
		step1_data(state,payload){
			state.step1_data = payload
		},
		step2_data(state,payload){
			state.step2_data = payload
		},
		step3_data(state,payload){
			state.step3_data = payload
		},
		step4_data(state,payload){
			state.step4_data = payload
		},
		update_step_state(state, payload) {
			state.steps_state = { ...state.steps_state, ...payload } 
		},

		CLEAR(state) {
			state.step1_data = {}
			state.step2_data = {}
			state.step3_data = {}
			state.step4_data = {}
			state.steps_state = {
				step1: false,
				step2: false
			}
		}

	},

})