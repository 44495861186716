<template  lang="pug">
	v-btn(color="blue-500" class="white--text") {{text}}
</template>
<script>
	export default {
		name:"BtnPrimary",
		props: {
			text: {
				type: String,
				default: '',
			},
		},
		methods:{
			emitEvent: function(){
				console.log('click child');
			},

		}
	}
</script>