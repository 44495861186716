import axios from 'axios';
import {merge} from 'lodash';

export default class RecomendadorCatEndpointsService {

	constructor() {
		this.http = axios.create();
		this.apiUrl = 'https://cat-services.coppelomnicanal.com';
		this.apiKey = 'AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM'
	}

	httpCall(url, data) {
		let defaults = {
			url: this.apiUrl + url,
		};
		let mergeData = merge(defaults, data);
		return this.http(mergeData);
	}

	/**
	 * Submit ajax get
	 * @param {string} url
	 * @param {object} params
 	*/
	doGet(url, params) {
		let config = {
			method: 'GET',
		}
		if (params) {
			config['params'] = params
		}
		return this.httpCall(url, config);
	}

	/**
	 * Submit ajax post
	 * @param {string} url
	 * @param {FormData|Object} data
 	*/
	doPost(url, data) {
		let config = {
			method: 'POST',
		}
		if (data) {
			config['data'] = data
		}
		return this.httpCall(url, config);
	}

	fetchStateCities(state, city) {
		// defaults
		// estado: Ninguno
		// ciudad: Ninguno
		let queryString = {
			key: this.apiKey	
		}
		// let url = `/v5/get/ciudadesEstadoCat/ciudades/${state}/${city}?key=AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM`

		let url = `/v6/ciudadesEstado/${state}/${city}`

		https://cat-services.coppelomnicanal.com/v6/ciudadesEstado/CAMPECHE/Ninguno?key=AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM&key=AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM
		return this.doGet(url, queryString)
	}

	fetchSeleccionadorInputsComputo(params) {
		let queryString = {
			'key': 'AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM'
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Computadoras/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormComputo(params) {
		/**
		 * /v3/get/seleccionadoresCat/{seleccionador_obj}/{estado}/{ciudad}/{parameters}/{orden}/{precio}
		 * para ordenar por precio ascendente:  orden = asc
		 * para ordenar por precio descendente:  orden = des
		 * para no ordenar:  orden = Ninguno
		 * para el filtrar todos los productos que cuesten menos de 600 (por ejemplo)  precio = 600
		 */
		let queryString = {
			key: 'AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM'
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Computadoras/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchSeleccionadorInputsLlantas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Llantas1/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormLlantas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Llantas1/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchSeleccionadorInputsBaterias(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Baterias1/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormBaterias(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Baterias1/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	submitFormBaterias2(params) {
		// tambien le queda
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Baterias2/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorAiac(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Aiac/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormAiac(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Aiac/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorCalef(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Calef/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormCalef(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Calef/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorCoolers(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Coolers/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormCoolers(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Coolers/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorCalentadores(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Calentadores/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormCalentadores(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Calentadores/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorMotocicletas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Motocicletas/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormMotocicletas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Motocicletas/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsPorMenosDe(params) {
		let queryString = {
			key: this.apiKey	
		}
		// let url = `/v5/get/topsCat/${params.estado}/${params.ciudad}/tops_filtros/${params.area}/${params.departamento}/${params.primernivel}/${params.segundonivel}/${params.tercernivel}/${params.cuartonivel}/${params.precioMaximo}`
		let url = `/v6/tops/${params.estado}/${params.ciudad}/tops_filtros/${params.area}/${params.departamento}/${params.primernivel}/${params.segundonivel}/${params.tercernivel}/${params.cuartonivel}/${params.precioMaximo}`
		return this.doGet(url, queryString)
	}

	submitFormPorMenosDe(params) {
		let queryString = {
			key: this.apiKey	
		}
		// let url = `/v5/get/topsCat/${params.estado}/${params.ciudad}/tops_prods/${params.area}/${params.departamento}/${params.primernivel}/${params.segundonivel}/${params.tercernivel}/${params.cuartonivel}/${params.precioMaximo}`
		let url = `/v6/tops/${params.estado}/${params.ciudad}/tops_prods/${params.area}/${params.departamento}/${params.primernivel}/${params.segundonivel}/${params.tercernivel}/${params.cuartonivel}/${params.precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorLavadores(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Lavadoras/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormLavadoras(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Lavadoras/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorRefrigeradores(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Refrigeradores/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormRefrigeradores(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Refrigeradores/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorColchones(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Colchones/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormColchones(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Colchones/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorPantallas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Pantallas/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormPantallas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Pantallas/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorSalas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/salas/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormSalas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/salas/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorCelulares(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Celulares/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormCelulares(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Celulares/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorEstufas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Estufas/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormEstufas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Estufas/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}

	fetchInputsSeleccionadorCocinas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { filters } = params
		let url = `/v3/get/filtrosCat/Cocinas/${estado}/${ciudad}/${filters}`
		return this.doGet(url, queryString)
	}

	submitFormCocinas(params) {
		let queryString = {
			key: this.apiKey	
		}
		let { estado } = params
		let { ciudad } = params
		let { precioMaximo } = params
		let { orden } = params
		let { filters } = params
		let url = `/v3/get/seleccionadoresCat/Cocinas/${estado}/${ciudad}/${filters}/${orden}/${precioMaximo}`
		return this.doGet(url, queryString)
	}
}

